<template>
    <draggable
        class="dragArea ali2"
        tag="ul"
        :list="tasks"
        :group="{ name: 'g1' }"
    >
        <li v-for="el in tasks" :key="el.id">
            <div class="itme-blocks">{{ el.name }}</div>
            <nested-draggable :tasks="el.tasks" />
        </li>
    </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
    props: {
        tasks: {
            required: true,
        },
    },
    components: {
        draggable,
    },
    name: "nested-draggable",
};
</script>
<style lang="scss">
.dragArea {
    min-height: 50px;
    // outline: 1px dashed;
    li > ul {
        margin-left: 20px;
        min-height: 0px;
        outline: 1px;
        .itme-blocks {
            padding: 10px 7px;
            margin-bottom: 5px;
            border: 1px solid #c7c4c4;
            border-radius: 2px;
            color: #7d7d7d;
            font-size: 16px;
            cursor: all-scroll;
        }
    }
    .itme-blocks {
        padding: 10px 7px;
        margin-bottom: 7px;
        border: 1px solid #0d67c9;
        background: #0d67c9;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        cursor: all-scroll;
    }
}
</style>
