<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                {{ $t("message.department_title") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="12" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'DepartmentController@store'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <el-row :gutter="20">
          <el-col :xs="22" :sm="6">
            <nested-draggable :tasks="list"></nested-draggable>
           
          </el-col>
          <el-col :xs="2" :sm="18">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
              <thead>
                <tr>
                  <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                  </th>

                  <th v-if="columns.name.show">
                    {{ columns.name.title }}
                  </th>

                  <th v-if="columns.branch_id.show">
                    {{ columns.branch_id.title }}
                  </th>

                  <th v-if="columns.number_staff.show">
                    {{ columns.number_staff.title }}
                  </th>

                  <th v-if="columns.parent_department_id.show">
                    {{ columns.parent_department_id.title }}
                  </th>

                  <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                  </th>

                  <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                  </th>

                  <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                  </th>
                </tr>

                <tr class="filter_sorche">
                  <th v-if="columns.id.show">
                    <el-input
                      clearable
                      size="mini"
                      v-model="filterForm.id"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      :placeholder="columns.id.title"
                      class="id_input"
                    ></el-input>
                  </th>

                  <th v-if="columns.name.show">
                    <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                    ></crm-input>
                  </th>
                  <th v-if="columns.parent_department_id.show">
                    <select-branch
                      :placeholder="columns.branch_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.branch_id"                      
                    ></select-branch>                  
                  </th>

                  <th v-if="columns.number_staff.show">
                    <crm-input
                      :placeholder="columns.number_staff.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.number_staff"                      
                    ></crm-input>                  
                  </th>

                  <th v-if="columns.parent_department_id.show">
                    <select-department
                      :placeholder="columns.parent_department_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.parent_department_id"                      
                    ></select-department>                  
                  </th>
                  <th v-if="columns.created_at.show">
                    <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                    ></crm-date-picker>
                  </th>

                  <th v-if="columns.updated_at.show">
                    <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                    ></crm-date-picker>
                  </th>

                  <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
              </thead>

              <transition-group name="flip-list" tag="tbody">
                <tr
                  v-for="department in list"
                  :key="department.id + 'departments'"
                  class="cursor-pointer"
                >
                  <td v-if="columns.id.show">{{ department.id }}</td>

                  <td v-if="columns.name.show">
                    {{ department.name }}
                  </td>

                  <td v-if="columns.branch_id.show">
                    {{ department.branch ? department.branch.name : null }}
                  </td>

                  <td v-if="columns.number_staff.show">
                    {{  department.number_staff }}
                  </td>

                  <td v-if="columns.parent_department_id.show">
                    {{
                      department.parent_department
                        ? department.parent_department.name
                        : null
                    }}
                  </td>

                  <td v-if="columns.created_at.show">
                    {{ department.created_at }}
                  </td>

                  <td v-if="columns.updated_at.show">
                    {{ department.updated_at }}
                  </td>

                  <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                      :name="$options.name"
                      :model="department"
                      :permissionShow="'DepartmentController@update'"
                      :permissionDestroy="'DepartmentController@destroy'"
                      :actions="actions"
                      @edit="edit"
                      @delete="destroy"
                    ></crm-settings>
                  </td>
                </tr>
              </transition-group>
            </table>
          </el-col>
        </el-row>

        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :visible.sync="drawerUpdate"
        size="70%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import nestedDraggable from "./components/nestedDraggable";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectBranch from "@/components/filters/inventory/select-branch";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "DepartmentController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    nestedDraggable,
    selectDepartment,
    selectBranch
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "department/list",
      columns: "department/columns",
      pagination: "department/pagination",
      filter: "department/filter",
      sort: "department/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "department/index",
      setPagination: "department/setPagination",
      updateSort: "department/updateSort",
      updateFilter: "department/updateFilter",
      updateColumn: "department/updateColumn",
      updatePagination: "department/updatePagination",
      show: "department/show",
      empty: "department/empty",
      delete: "department/destroy",
      refreshData: "department/refreshData",
    }),
  },
};
</script>

